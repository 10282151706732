<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Wallet Summary">
      <b-row>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                @change="fetchData"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                @change="fetchData"
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="tagTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(picture)="data">
              <b-avatar :src="data.value" />
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(actions)="items">
              <div>
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/vendor/wallet/show/' + items.item.id }"
                >
                  <feather-icon
                    style="width:18px; height:18px;"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- delete button -->
                <!-- <b-button v-if="authPermissions.includes('delete')" style='padding:7px; margin:6px; width:35px; height:35px;' class="btn-icon rounded-circle" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit" @click="deleteData(items.item.id)">
                                <feather-icon icon="Trash2Icon" style="width:18px; height:18px;" />
                            </b-button> -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {

  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "vendor_name",
          label: "Vendor Name",
        },
        {
          key: "storename",
          label: "Store Name",
        },
        {
          key: "total_sale",
          label: "total sale",
          sortable: true,
        },
        {
          key: "margin_amount",
          label: "margin amount",
          sortable: true,
        },
        {
          key: "received_amount",
          label: "received amount",
          sortable: true,
        },
        {
          key: "available_amount",
          label: "available amount",
          sortable: true,
        },
        {
          key: "pending_amount",
          label: "pending amount",
          sortable: true,
        },
        {
          key: "last_paid",
          label: "last paid",
          sortable: true,
        },
        {
          key: "offer_amount",
          label: "offer amount",
          sortable: true,
        },
        {
          key: "bundle_offer",
          label: "bundle offer",
          sortable: true,
        },
        {
          key: "actions",
        },
      ],
      items: [],
      exportItems: [],
    };
  },
  created() {
    // request tags index
    this.fetchData();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
    watch: {
      sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter.length == 0 || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    // fetchData of vendors
    fetchData() {
      this.showDismissibleAlert = false;
      // request vendors index API
      var url = null;
      if (this.filter == "") {
        url =
          "vendor-wallet/pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "vendor-wallet/pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
         
          this.items = result.data.data.data
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].picture;
      }
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].store,
          this.items[index].type,
          this.items[index].status,
          this.items[index].code,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [
          [
            "ID",
            "Name",
            "Store",
            "Total Sale",
            "Margin Amount",
            "Received Amount",
            "Avaliable Amount",
            "Pending Amount",
            "Last Paid",
            "Offer Amount",
          ],
        ],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
